import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import Header from "../components/header"
import TestiCards from "../components/testicards"
import FAQ from "../components/FAQ"
import FooterContainer from "../components/FooterContainer"
import Signup from "../components/Signup"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import phones from "../assets/Phones2.svg"
import shadowPhones from "../assets/shadowPhones.svg"

import path1 from "../assets/Arrow1.svg"
import path2 from "../assets/Arrow2.svg"
import path3 from "../assets/Arrow3.svg"

import icon1 from "../assets/ICON.svg"
import icon2 from "../assets/ICON2.svg"
import icon3 from "../assets/ICON3.svg"
import icon4 from "../assets/ICON4.svg"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"

const DefaultPageLight = props => {
	return (
		<Body>
			
			<GlobalFonts />

			
			<H1Text></H1Text>
			<PText></PText>


		</Body>
	)
}

export default DefaultPageLight

const Body = styled.div`
  background-color: transparent;
`

const TopBackground = styled.img`
	position: absolute;
	left: -24.63%;
	right: 9.75%;
	top: -2.3%;
	bottom: 78.14%;

	background: #8bbef3;
	border-radius: 333px;
	transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const TopBackground2 = styled.img`
	position: absolute;
	left: -14.72%;
	right: 32.8%;
	top: -16.64%;
	bottom: 81.18%;

	background: #8bbef3;
	opacity: 0.05;
	border-radius: 333px;
	transform: matrix(1, -0.1, 0.08, 1, 0, 0);

`
const H1Text = styled.h1`
  position:relative;
  width: 40%;
  height: 89.66px;
  left: 319.69px;
  top: 193.23px;
  color:white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  color: #ffffff;
        @media only screen and (max-width: 425px) and (min-width: 100px) {
left:119px;
  }
`

const PText = styled.p`
  position:relative;
  width: 439.52px;
  height: 43.18px;
  left: 323.28px;
  top: 290.93px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`


const BackgroundSlide = styled.img`
	position: absolute;
	width: 100%;
	opacity: 40%;
	z-index: -6;
	top: 2339.21px;
`

const FooterDiv = styled.div`
	position: relative;
	width: 100%;
	z-index: 1;
	left: 0px;
	top: 700px;
`

const SignupDiv = styled.div`
	position: relative;


	width: 100%;
	height: 300px;
	z-index: 2;
	top: 750.21px;
	display: flex;
	align-items: center;
	justify-content: center;
`
